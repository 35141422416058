/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { GetData } from "apiHandler";
import TableComponent from "components/Table";
import { useHistory } from "react-router-dom";
import Loader from "../../components/Loader";
import toastr from "toastr";
import moment from "moment";
import "toastr/build/toastr.min.css";
// import "./OrdersTable.scss";

const DisputeManagement = () => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0,
  });
  const columns = [
    { key: "complainNumber", label: "Ticket Number" },
    { key: "companyName", label: "Company Name" },
    { key: "issueType", label: "Issue Type" },
    // { key: "issueDescription", label: "Issue Description" },
    { key: "createdAt", label: "Raised on" },
    { key: "status", label: "Status" },
    // { key: "remark", label: "Remarks" },
  ];

  const [disputeListing, setDisputeListing] = useState([]);

  const StatusBadge = ({ status }) => {
    const getStatusStyles = (status) => {
      switch (status.toLowerCase()) {
        case "active":
          return { background: "#e1f3dc", color: "#237804" };
        case "closed":
          return { background: "#ffcccc", color: "#d32f2f" };
        case "hold":
          return { background: "#fff3cd", color: "#856404" };
        default:
          return { background: "#e0e0e0", color: "#000" };
      }
    };

    return (
      <span
        style={{
          borderRadius: "12px",
          fontSize: "12px",
          lineHeight: "24px",
          padding: "0 18px",
          textTransform: "capitalize",
          width: "fit-content",
          display: "inline-block",
          fontWeight: "500",
          ...getStatusStyles(status),
        }}
      >
        {status}
      </span>
    );
  };

  const getDisputeListing = (p = 1) => {
    const pageSize = 20;
    setLoader(true);
    GetData(`admin/disputes?page=${p}&pageSize=${pageSize}`).then((response) => {
      setLoader(false);
      if (response.status === true) {
        if (response?.result?.disputes.length) {
          setDisputeListing(
            response?.result?.disputes?.map((item) => ({
              ...item,
              createdAt: moment(item.createdAt).format("LL"),
              issueType: item.issueType
                ? item.issueType.charAt(0).toUpperCase() +
                  item.issueType.slice(1).toLowerCase()
                : "",
              status: (
                <StatusBadge
                  status={
                    item.status
                      ? item.status.charAt(0).toUpperCase() +
                        item.status.slice(1).toLowerCase()
                      : ""
                  }
                />
              ),
            }))
          );
          setPaging({
            currentPage: p,
            recordPerPage: pageSize,
            totalRecord: response?.result?.count,
          });
        } else {
          setDisputeListing([]);
          setPaging({
            currentPage: 1,
            recordPerPage: pageSize,
            totalRecord: 0,
          });
        }
      } else {
        setDisputeListing([]);
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 });
        toastr.error(response?.message, "Error");
      }
    });
  };

  useEffect(() => {
    getDisputeListing();
  }, []);

  const handleActions = (key, data) => {
    if (key === "view") {
      history.push("/orders/" + data.id);
    }
  };

  const handleRow = (data) => {
    history.push("/dispute/" + data.id);
  };

  const paginationHandler = (page) => {
    getDisputeListing(page);
  };

  return (
    <>
      <Loader show={loader} />
      <div className="page-content">
        <Container fluid={true} className="customer-container">
          <div className="products-nav">
            <span className="products-heading">Dispute Management</span>
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  {/* <hr className=" mt-0 mb-4 tab-hr"></hr> */}
                  <div className="order-table">
                    <TableComponent
                      columns={columns}
                      content={disputeListing}
                      handleActions={handleActions}
                      paging={paging}
                      paginationHandler={paginationHandler}
                      onClick={handleRow}
                      loading={loader}
                      //   sorting={handleSort}
                      //   arrowState={sortingState}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DisputeManagement;
