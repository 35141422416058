/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import back from '../../assets/images/back2.svg'
import verified from '../../assets/images/verified.svg'
import { Card, CardBody, Container, Row, Col } from 'reactstrap'
import { Link, useParams } from 'react-router-dom'
import { GetData } from '../../apiHandler'
import toastr from 'toastr'
import EditStaff from './EditStaff'
import moment from 'moment'
import Loader from '../../components/Loader'
import NumberFormat from 'react-number-format'

function StaffInformation () {
  const userObj = JSON.parse(localStorage.authUser)
  // eslint-disable-next-line no-unused-vars
  const [currentRole, setCurrentRole] = useState(1)
  const params = useParams()
  const [details, setDetails] = useState({})
  const [edit, setEdit] = useState(true)
  const [loader, setLoader] = useState(false)
  const [permissionsDataLeft, setPermissionsDataLeft] = useState([])
  const [permissionsDataRight, setPermissionsDataRight] = useState([])
  const [selectedPermission, setselectedPermission] = useState([])
  const [disableStatus, setDisableStatus] = useState()
  const [warehouses, setWarehouses] = useState([])

  useEffect(() => {
    if (localStorage.authUser) {
      const userObj1 = JSON.parse(localStorage.authUser)
      setCurrentRole(userObj1.roleId)
    }
  }, [])

  useEffect(() => {
    getStaffDetails()
    let roleId = 2
    if (userObj.roleId == 3 || userObj.roleId == 4) {
      roleId = 4
    }
    getPermissions(roleId)
    if (userObj.roleId == 3 || userObj.roleId == 4) {
      fetchWarehouse()
    }
  }, [])

  const fetchWarehouse = () => {
    const url = 'vendor/getWarehouseLocationsWithoutPagination'

    GetData(url).then((response) => {
      if (response.status) {
        if (response.result.data.length > 0) {
          const warehouses = response.result.data.map((warehouse) => {
            return { value: warehouse.id, label: warehouse.locationName }
          })
          setWarehouses(prevState => ([...prevState, ...warehouses]))
        }
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const getStaffDetails = () => {
    setLoader(true)
    let url = 'admin/getStaffDetails/' + params.id
    if (userObj.roleId == 3 || userObj.roleId == 4) {
      url = 'vendor/getStaffDetails/' + params.id
    }
    GetData(url).then((response) => {
      if (response.status === true) {
        setDetails(response?.result)
        setDisableStatus(response.result.status == 1)
        if (response?.result?.roleId) {
          getPermissions(response?.result?.roleId)
        }
        const arr = []
        response?.result?.userPermissions?.map((item) => {
          arr.push(item.permissions.id)
        })
        setselectedPermission(arr)
      } else {
        toastr.error(response.message, 'Error')
      }
      setLoader(false)
    })
  }

  const getPermissions = (roleId) => {
    GetData('admin/modules/get?areaCode=' + roleId).then((response) => {
      if (response.status === true) {
        setPermissionsDataLeft(
          response.result.slice(0, response.result.length / 2)
        )
        setPermissionsDataRight(
          response.result.slice(response.result.length / 2)
        )
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const handleDisable = () => {
    let url = 'admin/changeStaffStatus/' + params?.id
    if (userObj.roleId == 3 || userObj.roleId == 4) {
      url = 'vendor/changeStaffstatus/' + params?.id
    }
    GetData(url).then((response) => {
      if (response.status == true) {
        toastr.success(response.message, 'Success')
        getStaffDetails()
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  return (
    <>
      <div className="page-content">
        <Loader show={loader} />
        <Container fluid={true} className="customer-container">
          <div className="staff-management-main">
            <div className="staff-management-heading">
              <Link to="/staff-management">
                <img src={back} style={{ marginRight: '8px' }} />
              </Link>{' '}
              <span style={{ fontWeight: '700' }}>Staff Information</span>
            </div>
            <div className="main-add-status">
              <span className="staff-information-add">
                Added on: {moment(details?.createdAt).format('LL LT')}{' '}
              </span>
              {details?.status === 1 || details?.status === 4
                ? (
                <>
                <div className="status-active__div">
                  <span className="staff-status">
                    Status:{' '}
                    <span className="status-active">
                      {details?.status === 1 ? 'Active' : 'Disable'}
                    </span>
                  </span>

                  <div className="form-check form-switch form-switch-lg ">
                    <input
                      type="checkbox"
                      className="form-check-input status-check-input"
                      id="customSwitchsizelg"
                      checked={disableStatus}
                      value={params.id}
                      onClick={(e) => {
                        setDisableStatus((prev) => !prev)
                        handleDisable()
                      }}
                    />
                  </div>
                  </div>
                </>
                  )
                : null}
            </div>
          </div>
          {edit
            ? (
            <Row className="mt-3">
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <span className="staff-main-name">
                        {details?.firstName} {details?.lastName}
                      </span>
                      <span
                        className="staff-main-name information-edit"
                        onClick={(e) => {
                          e.preventDefault()
                          setEdit(false)
                        }}
                      >
                        <i className="bx bx-edit-alt" style={{ fontSize: '18px' }}></i> Edit
                      </span>
                    </div>
                    <hr className=" mt-4 mb-4"></hr>
                    <div className="staff-information-main">
                      <div className="staff-information-div">
                        <div className="information--div">
                          <div className="mb-3">
                            <div className="staff-manafement-light">Name</div>
                            <div className="staff-manafement-dark">
                              <p style={{ fontWeight: '700' }}>{details?.firstName} {details?.lastName}</p>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div className="staff-manafement-light">
                              Mobile Number
                            </div>
                            <div className="staff-manafement-dark">
                            <p style={{ fontWeight: '700' }}>
                              {
                                details?.mobile
                                  ? <NumberFormat value={details?.mobile} displayType={'text'} format="+1 (###) ###-####"/>
                                  : '--'
                              }
                              </p>
                            </div>
                          </div>
                          <div className="mb-4">
                            <div className="staff-manafement-light">
                              Title
                            </div>
                            <div className="staff-manafement-dark">
                            <p style={{ fontWeight: '700' }}>{details?.designation}</p>
                            </div>
                          </div>
                          {(userObj.roleId == 3 || userObj.roleId == 4) &&
                          <div className="mb-4">
                            <div className="staff-manafement-light">
                              Warehouse
                            </div>
                            <div className="staff-manafement-dark">
                            <p style={{ fontWeight: '700' }}>{warehouses.filter((warehouse) => {
                              return warehouse.value == details?.staffWareHouseLocation?.vendorWarehouseLocationId
                            })[0]?.label}</p>
                            </div>
                          </div>}
                        </div>
                        <div className="information--div">
                          <div className="mb-3">
                            <div className="staff-manafement-light">
                              Email Address{' '}
                              {details?.isEmailVerified === 1 && (
                                <span>
                                  <img
                                    src={verified}
                                    className="verified-icon"
                                  />
                                  <span style={{ color: '#5aa327' }}>
                                    Verified
                                  </span>
                                </span>
                              )}
                            </div>
                            <div className="staff-manafement-dark">
                            <p style={{ fontWeight: '700' }}>{details?.email}</p>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div className="staff-manafement-light">
                              Phone Number
                            </div>
                            <div className="staff-manafement-dark">
                            <p style={{ fontWeight: '700' }}>
                              {
                                details?.phone
                                  ? <NumberFormat value={details?.phone} displayType={'text'} format="+1 (###) ###-####"/>
                                  : '--'
                              }
                              </p>
                            </div>
                          </div>
                          {details?.roleId
                            ? <div className="mb-4">
                            <div className="staff-manafement-light">
                              Type
                            </div>
                            <div className="staff-manafement-dark">
                              {details?.roleId == 4 ? 'Staff' : 'Driver'}
                            </div>
                          </div>
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="staff-information-second-main">
                      <div className="staff-manafement-dark mb-3">
                        Privileges
                      </div>
                      <div className="staff-information-div">
                        <div className="staff-manafement-light information--div">
                          <div className="mb-1">
                            <input
                              type="checkbox"
                              className="staff-check-box"
                              checked={
                                details?.userPermissions?.length ===
                                permissionsDataLeft.length +
                                  permissionsDataRight.length
                              }
                            />
                            All
                          </div>
                          {permissionsDataLeft.map((data) => {
                            const checked = selectedPermission?.includes(
                              data.id
                            )
                            return (
                              <div className="mb-1" key={data.id}>
                                <input
                                  type="checkbox"
                                  className="staff-check-box"
                                  checked={checked}
                                />
                                {data.name}
                              </div>
                            )
                          })}
                        </div>
                        <div className="staff-manafement-light information--div">
                          {permissionsDataRight.map((data) => {
                            const checked = selectedPermission?.includes(
                              data.id
                            )
                            return (
                              <div className="mb-1" key={data.id}>
                                <input
                                  type="checkbox"
                                  className="staff-check-box"
                                  checked={checked}
                                />
                                {data.name}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
              )
            : (
            <EditStaff
              details={details}
              setEdit={setEdit}
              getStaffDetails={getStaffDetails}
              warehouses={warehouses}
            />
              )}
        </Container>
      </div>
    </>
  )
}
export default StaffInformation
