/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { GetData, PostData, PutData } from "../../apiHandler";
import Loader from "../../components/Loader";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import TableComponent from "../../components/Table";
import ConfirmationModal from "../../components/Common/ConfirmationModal";
import Select from "react-select";
import Breadcrumb from "components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";

export default function MasterCustomerDetails() {
  const history = useHistory();
  const { id } = useParams();
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0,
  });
  const [loader, setLoader] = useState(false);
  const [masterCustomer, setMasterCustomer] = useState({});
  const [childCustomers, setChildCustomers] = useState([]);
  const [isChildModalOpen, setIsChildModalOpen] = useState(false);
  const [selectedChildCustomers, setSelectedChildCustomers] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [childToDelete, setChildToDelete] = useState(null);
  const [availableChildCustomers, setAvailableChildCustomers] = useState([]);

  useEffect(() => {
    fetchChildCustomers();
    getCustomerProfile(id);
    fetchAvailableChildCustomers();
  }, [id]);

  const fetchChildCustomers = (p = 1) => {
    const pageSize = 20;
    let url =
      "admin/child-customers/" + id + "/?page=" + p + "&pageSize=" + pageSize;
    // if (filterByStatus !== 'all') {
    //   url += '&status=' + filterByStatus
    // }
    // if (filterByKeyObject.string) {
    //   url +=
    //     '&filterBy=' +
    //     filterByKeyObject.key +
    //     '&search=' +
    //     filterByKeyObject.string
    // }
    // if (sortingState.sortColumn != null) {
    //   url +=
    //     '&sortBy=' + sortingState.sortColumn + '&order=' + sortingState.order
    // }
    setLoader(true);
    GetData(url).then((response) => {
      setLoader(false);
      if (response.status === true) {
        if (response?.result?.data.length) {
          const updatedChildCustomers = response.result.data.map(
            (childCustomer) => {
              return { ...childCustomer, action: ["delete"] };
            }
          );
          setChildCustomers(updatedChildCustomers);
          // setChildCustomers(response?.result?.data);
          setPaging({
            currentPage: p,
            recordPerPage: pageSize,
            totalRecord: response?.result?.total,
          });
        } else {
          setPaging({
            currentPage: p,
            recordPerPage: pageSize,
            totalRecord: 0,
          });
          setChildCustomers([]);
        }
      } else {
        setChildCustomers([]);
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 });
        toastr.error(response?.message, "Error");
      }
    });
  };

  const getCustomerProfile = (id) => {
    setLoader(true);
    GetData("admin/getCustomerProfileInfo/" + id).then((response) => {
      setLoader(false);
      if (response.status === true) {
        setMasterCustomer(response.result);
      } else {
        toastr.error(response.message, "Error");
      }
    });
  };

  const fetchAvailableChildCustomers = () => {
    GetData("admin/customers/independent-list?page=1&pageSize=20").then(
      (response) => {
        if (response.status) {
          const customerOptions = response.result.data.map((customer) => ({
            label: customer.companyName,
            value: customer.id,
          }));
          setAvailableChildCustomers(customerOptions);
        } else {
          toastr.error(response.message, "Error");
        }
      }
    );
  };

  const toggleChildModal = () => {
    setIsChildModalOpen(!isChildModalOpen);
  };

  const handleAddChildCustomers = () => {
    setLoader(true);
    PutData(`admin/add-child-customer/${id}`, {
      childCustomerIds: selectedChildCustomers,
    }).then((response) => {
      if (response.status) {
        fetchChildCustomers();
        fetchAvailableChildCustomers()
        toggleChildModal();
      } else {
        toastr.error(response.message, "Error");
      }
    });
  };

  const handleRemoveChildCustomer = (childId) => {
    setChildToDelete(childId);
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteChild = () => {
    setLoader(true);
    PutData(`admin/remove-child-customer/${id}`, {
      childCustomerIds: [childToDelete],
    }).then((response) => {
      setLoader(false);
      if (response.status) {
        fetchChildCustomers();
        fetchAvailableChildCustomers()
        setIsDeleteModalOpen(false);
      } else {
        toastr.error(response.message, "Error");
      }
    });
  };

  const columns = [
    { key: "companyName", label: "Child Company Name" },
    { key: "email", label: "Email" },
    { key: "action", label: "Actions" },
  ];

  const paginationHandler = (page) => {
    fetchChildCustomers(page);
  };

  return (
    <>
      <Loader show={loader} />
      <div className="page-content">
        <MetaTags>
          <title>Master Customer Details</title>
        </MetaTags>
        <Container fluid={true}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <i
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/master-customer")}
                className="dripicons-arrow-thin-left me-3"
              ></i>
              <Breadcrumb
                title="UI Elements"
                breadcrumbItem={masterCustomer?.companyName}
              />
            </div>
            {availableChildCustomers.length && (
              <div className="imp-exp-div">
                <button
                  className="add-new-customer-btn"
                  onClick={toggleChildModal}
                >
                  + Add Child Customers
                </button>
              </div>
            )}
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  {/* <h4>{masterCustomer?.companyName}</h4> */}
                  {/* <p>Email: {masterCustomer?.email}</p> */}
                  <TableComponent
                    columns={columns}
                    content={childCustomers}
                    paging={paging}
                    loading={loader}
                    paginationHandler={paginationHandler}
                    handleActions={(action, child) => {
                      if (action === "delete") {
                        handleRemoveChildCustomer(child.id);
                      }
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal isOpen={isChildModalOpen} toggle={toggleChildModal}>
        <ModalHeader toggle={toggleChildModal}>
          Select Child Customers
        </ModalHeader>
        <ModalBody>
          <Select
            options={availableChildCustomers}
            onChange={(selected) =>
              setSelectedChildCustomers(selected.map((option) => option.value))
            }
            closeMenuOnSelect={false}
            isMulti
            placeholder="Select Child Customers"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAddChildCustomers}>
            Add
          </Button>
          <Button color="secondary" onClick={toggleChildModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(false)}
        onConfirm={confirmDeleteChild}
        message="Are you sure you want to remove this child customer?"
      />
    </>
  );
}
