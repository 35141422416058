import React, { useEffect, useState } from 'react'
import { PostData } from 'apiHandler'
import { useParams } from 'react-router-dom'
import TableComponent from 'components/Table'
import PropTypes from 'prop-types'
import moment from 'moment'
import Loader from '../../components/Loader'

const pageSize = 20
function OrderList ({ display }) {
  const params = useParams()
  const [loader, setLoader] = useState(false)
  const [orderData, setOrderData] = useState([])
  const columns = [
    { key: 'po_number', label: 'P.O. Number' },
    { key: 'orderNumber', label: 'Order' },
    { key: 'orderDate', label: 'Order Date' },
    { key: 'customerName', label: 'Customer Name' },
    { key: 'orderStatus', label: 'Order Status' },
    { key: 'items', label: 'items', sorting: false }
  ]
  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })
  useEffect(() => {
    if (display === true) {
      getOrderList()
    }
  }, [display])
  const paginationHandler = (page) => {
    getOrderList(page)
  }
  const getItemList = (products, i) => {
    return <div className='info-icon'><div className='d-flex align-items-center'><span>{`${products.length} Item (+${
      products.reduce((acc, item) => {
        acc += item.orderedQuantity
        return acc
      }, 0)
    })`}</span>{' '}<i className='bx bxs-down-arrow'></i>
    </div>
    <div className="tooltiptext1 arrow">
                                            <div className='tooltipdata'>
                                                <span className='part--id'>Part No.</span>
                                                <span className='part--id'>QTY</span>
                                            </div>
                                            <div className='tooltip-innerdata'>
                                            {
          products.map((product, key) => {
            return (
              <div className='partid-qty-div' key={key}>
                <span>{product?.partnumberinfo?.partNumber ? product.partnumberinfo.partNumber : '--'}</span>
                <span>{product.orderedQuantity ? product.orderedQuantity : '--'}</span>
              </div>
            )
          })
        }
                                            </div>
                                           </div></div>
  }

  const getOrderList = (p = 1) => {
    const data = {
      customerId: parseInt(params.id),
      page: p,
      pageSize: pageSize,
      filterBy: 'All',
      search: ''
    }
    setLoader(true)
    PostData('admin/getCustomerOrderList', data).then((response) => {
      setLoader(false)
      if (response.status === true || response.status === 200) {
        if (response.result) {
          const tableData = []
          response.result.orders?.map((item, i) => {
            let statusString = <div className="pending-div">Pending</div>
            if (item.orderStatus === 1) {
              statusString = <div className="active-div">Active</div>
            }
            if (item.orderStatus === 2) {
              statusString = <div className="approved-div">Approved</div>
            }
            if (item.orderStatus === 3) {
              statusString = <div className="rejected-div">Rejected</div>
            }
            if (item.orderStatus === 4) {
              statusString = <div className="disabled-div">Disabled</div>
            }
            // const customer = item.parentOrder.customer.primaryFirstName + item.parentOrder.customer.primaryLastName
            const customer = <div>
                <div>{item.shippingFirstName + ' ' + item.shippingLastName}</div>
                <div>{item.shippingCountry + ' , ' + item.shippingState}</div>
            </div>
            tableData.push({
              orderNumber: item.orderNumber,
              orderDate: moment(item.orderDate).format('LL LT'),
              customerName: customer,
              orderStatus: statusString,
              items: getItemList(item.orderProducts, i),
              po_number: item?.po_number

            })
            setPaging({
              currentPage: p,
              recordPerPage: pageSize,
              totalRecord: response.result.count
            })
            setOrderData(tableData)
            return null
          })
        }
      }
    //   setPaging({
    //     currentPage: p,
    //     recordPerPage: pageSize,
    //     totalRecord: response.result.total
    //   })
    })
  }
  const handleSort = (e, column) => {
    e.preventDefault()
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'ASC' })
    } else if (sortingState.sortColumn === column) {
      if (sortingState.order === 'DESC') {
        setSortingState({ sortColumn: column, order: 'ASC' })
      } else setSortingState({ sortColumn: column, order: 'DESC' })
    } else {
      setSortingState({ sortColumn: column, order: 'ASC' })
    }
  }
  useEffect(() => {
    sortingState.sortColumn && sortingState.order && getOrderList(1)
  }, [sortingState])
  return (
     <div>
        <Loader show={loader} />
        <div className="staff-main-table">
            <TableComponent
            columns={columns}
            content={orderData}
            paging={paging}
            paginationHandler={paginationHandler}
            sorting={handleSort}
            arrowState={sortingState}
            />
        </div>
    </div>
  )
}
export default OrderList

OrderList.propTypes = {
  display: PropTypes.any
}
