/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Loader from '../../components/Loader'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Modal
} from 'reactstrap'
import { GetData } from 'apiHandler'
import moment from 'moment'
import classnames from 'classnames'
import DatePicker from 'react-datepicker'
import TableComponent from 'components/Table'
import NumberFormat from 'react-number-format'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import './Reports.scss'
import { useHistory } from 'react-router-dom'

const ReportOrderOpen = () => {
  const [loader, setLoader] = useState(false)
  const [tab, setTab] = useState('1')
  const [reports, setReports] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [selectedData, setSelectedData] = useState({})
  const [isDisableOpen, setIsDisableOpen] = useState(false)
  const userObj = JSON.parse(localStorage.authUser)
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })
  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })
  const [columns, setColumns] = useState([
    { key: 'po_number', label: 'P.O. No' },
    { key: 'orderNumber', label: 'Order No', sorting: true },
    { key: 'costPriceTotal', label: 'Order Amount', sorting: true },
    { key: 'customerName', label: 'Customer Name' },
    { key: 'orderStatus', label: 'Order Status', sorting: true },
    { key: 'createdAt', label: 'Date' }
  ])
  const history = useHistory()

  const [datePresets, setDatePresets] = useState([{
    label: 'Today',
    value: 0,
    startDate: new Date(),
    endDate: new Date()
  },
  {
    label: 'Yesterday',
    value: 1,
    startDate: new Date(Date.now() - 864e5),
    endDate: new Date(Date.now() - 864e5)
  },
  {
    label: 'Last 7 days',
    value: 2,
    startDate: new Date(Date.now() - (7 * 864e5)),
    endDate: new Date()
  },
  {
    label: 'Last 30 days',
    value: 3,
    startDate: new Date(Date.now() - (30 * 864e5)),
    endDate: new Date()
  },
  {
    label: 'Last 90 days',
    value: 4,
    startDate: new Date(Date.now() - (90 * 864e5)),
    endDate: new Date()
  },
  {
    label: 'Last Month',
    value: 5,
    startDate: moment(moment(new Date()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss')).toDate(),
    endDate: moment(moment(new Date()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss')).toDate()
  },
  {
    label: 'Last Year',
    value: 6,
    startDate: moment(moment(new Date()).subtract(1, 'years').startOf('year').format('YYYY-MM-DD HH:mm:ss')).toDate(),
    endDate: moment(moment(new Date()).subtract(1, 'years').endOf('year').format('YYYY-MM-DD HH:mm:ss')).toDate()
  },
  {
    label: 'Week to date',
    value: 7,
    startDate: new Date(Date.now() - (7 * 864e5)),
    endDate: new Date()
  },
  {
    label: 'Month to date',
    value: 8,
    startDate: new Date(Date.now() - (30 * 864e5)),
    endDate: new Date()
  },
  {
    label: 'Quarter to date',
    value: 9,
    startDate: new Date(Date.now() - (90 * 864e5)),
    endDate: new Date()
  },
  {
    label: 'Year to date',
    value: 10,
    startDate: new Date(Date.now() - (365 * 864e5)),
    endDate: new Date()
  }
  ])

  useEffect(() => {
    return () => {
      setTab('1')
    }
  }, [])

  useEffect(() => {
    for (let i = 0; i < 4; i++) {
      let startDate = new Date()
      let endDate = new Date()
      const quarterLabel = moment().subtract((i + 1), 'Q').format('[Quarter]Q Y')
      if (quarterLabel.includes('Quarter1')) {
        const startStr = quarterLabel.substring(quarterLabel.length - 4) + '-01-01'
        const endStr = quarterLabel.substring(quarterLabel.length - 4) + '-03-31'
        startDate = moment(moment(startStr, 'YYYY-MM-DD HH:mm:ss')).toDate()
        endDate = moment(moment(endStr, 'YYYY-MM-DD HH:mm:ss')).toDate()
      }
      if (quarterLabel.includes('Quarter2')) {
        const startStr = quarterLabel.substring(quarterLabel.length - 4) + '-04-01'
        const endStr = quarterLabel.substring(quarterLabel.length - 4) + '-06-30'
        startDate = moment(moment(startStr, 'YYYY-MM-DD HH:mm:ss')).toDate()
        endDate = moment(moment(endStr, 'YYYY-MM-DD HH:mm:ss')).toDate()
      }
      if (quarterLabel.includes('Quarter3')) {
        const startStr = quarterLabel.substring(quarterLabel.length - 4) + '-07-01'
        const endStr = quarterLabel.substring(quarterLabel.length - 4) + '-09-30'
        startDate = moment(moment(startStr, 'YYYY-MM-DD HH:mm:ss')).toDate()
        endDate = moment(moment(endStr, 'YYYY-MM-DD HH:mm:ss')).toDate()
      }
      if (quarterLabel.includes('Quarter4')) {
        const startStr = quarterLabel.substring(quarterLabel.length - 4) + '-10-01'
        const endStr = quarterLabel.substring(quarterLabel.length - 4) + '-12-31'
        startDate = moment(moment(startStr, 'YYYY-MM-DD HH:mm:ss')).toDate()
        endDate = moment(moment(endStr, 'YYYY-MM-DD HH:mm:ss')).toDate()
      }
      const quarterObj = {
        label: quarterLabel,
        value: 10 + (i + 1),
        startDate,
        endDate
      }
      setDatePresets((prevState) => ([
        ...prevState,
        quarterObj
      ]))
    }
  }, [])

  const paginationHandler = (page) => {
    getReports(page)
  }

  const handleSort = (e, column) => {
    e.preventDefault()
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'asc' })
    } else if (sortingState.sortColumn === column) {
      if (sortingState.order === 'desc') {
        setSortingState({ sortColumn: column, order: 'asc' })
      } else setSortingState({ sortColumn: column, order: 'desc' })
    } else {
      setSortingState({ sortColumn: column, order: 'asc' })
    }
  }

  useEffect(() => {
    sortingState.sortColumn && sortingState.order && getReports(paging.currentPage)
  }, [sortingState])

  useEffect(() => {
    if (fromDate && toDate) {
      getReports(1)
    }
  }, [fromDate, toDate])

  useEffect(() => {
    setFromDate(new Date())
    setToDate(new Date())
  }, [])

  const exportReport = (e) => {
    e.preventDefault()
    setLoader(true)
    const pageSize = 20
    let url = 'admin/exportOpenOrderReport?fromDate=' + moment(fromDate).format('YYYY-MM-DD') + '&toDate=' + moment(toDate).format('YYYY-MM-DD')

    if (userObj.roleId === 3 || userObj.roleId === 4) {
      url = 'vendor/getOpenOrderReportExport?fromDate=' + moment(fromDate).format('YYYY-MM-DD') + '&toDate=' + moment(toDate).format('YYYY-MM-DD')
    }

    GetData(url).then((response) => {
      if (response.status === true || response.status === 200) {
        setLoader(false)
        if (userObj.roleId === 3 || userObj.roleId === 4) {
          history.push({ pathname: '/report-open-order-export', state: { data: response.result.reports, value: 'Open Order Vendor' } })
        } else {
          history.push({ pathname: '/reports-open-order-export', state: { data: response.result.reports, value: 'Open Order admin' } })
        }
        // const a = document.createElement('a')
        // a.href = 'https://' + response.result
        // a.download = 'download'
        // a.click()
      } else {
        setLoader(false)
        toastr.error(response.message, 'Error')
      }
    })
  }

  const handleExport = (e) => {
    e.preventDefault()
    setLoader(true)
    const pageSize = 20
    let url = 'admin/exportOpenOrderReport?fromDate=' + moment(fromDate).format('YYYY-MM-DD') + '&toDate=' + moment(toDate).format('YYYY-MM-DD')

    if (userObj.roleId === 3 || userObj.roleId === 4) {
      url = 'vendor/getOpenOrderReportExport?fromDate=' + moment(fromDate).format('YYYY-MM-DD') + '&toDate=' + moment(toDate).format('YYYY-MM-DD')
    }

    GetData(url).then((response) => {
      if (response.status === true || response.status === 200) {
        setLoader(false)
        const a = document.createElement('a')
        a.href = 'https://' + response.result.path
        a.download = 'download'
        a.click()
      } else {
        setLoader(false)
        toastr.error(response.message, 'Error')
      }
    })
  }

  const getReports = (p = 1) => {
    setLoader(true)
    const pageSize = 20
    let url = 'admin/getOpenOrderReport?page=' + p + '&pageSize=' + pageSize + '&fromDate=' + moment(fromDate).format('YYYY-MM-DD') + '&toDate=' + moment(toDate).format('YYYY-MM-DD')

    if (userObj.roleId === 3 || userObj.roleId === 4) {
      url = 'vendor/getOpenOrderReport?page=' + p + '&pageSize=' + pageSize + '&fromDate=' + moment(fromDate).format('YYYY-MM-DD') + '&toDate=' + moment(toDate).format('YYYY-MM-DD')
    }

    if (sortingState.sortColumn != null) {
      url +=
        '&sortBy=' + sortingState.sortColumn + '&order=' + sortingState.order.toUpperCase()
    }

    if (!(userObj.roleId === 3 || userObj.roleId === 4)) {
      setColumns([
        { key: 'po_number', label: 'P.O. No' },
        { key: 'orderNumber', label: 'Order No', sorting: true },
        { key: 'total', label: 'Order Amount', sorting: true },
        { key: 'customerName', label: 'Customer Name' },
        { key: 'createdAt', label: 'Date' }
      ])
    }

    GetData(url).then((response) => {
      if (response.status === true || response.status === 200) {
        (userObj.roleId === 3 || userObj.roleId === 4)
          ? setReports(response.result.orders.map((order, index) => {
            const orderTotal = (!order.costPriceTotal || order.costPriceTotal === undefined) ? 0 : order.costPriceTotal
            const customerName = order.parentOrder.customer.primaryFirstName + ' ' + order.parentOrder.customer.primaryLastName
            let statusString = <div className="active-div">Delivered</div>
            if (order.orderStatus === 0) {
              statusString = <div className="disabled-div">New</div>
            }
            if (order.orderStatus === 1) {
              statusString = <div className="approved-div">In Progress</div>
            }
            if (order.orderStatus === 2) {
              statusString = <div className="approved-div">In Transit</div>
            }
            if (order.orderStatus === 3) {
              statusString = <div className="active-div">Delivered</div>
            }
            if (order.orderStatus === 4) {
              statusString = <div className="rejected-div">Rejected</div>
            }
            if (order.orderStatus === 5) {
              statusString = <div className="rejected-div">Cancelled</div>
            }
            return {
              orderNumber: order.parentOrder.orderNumber,
              customerName,
              orderStatus: statusString,
              costPriceInvoiceTotal: order.costPriceInvoiceTotal,
              createdAt: moment(order?.createdAt).format('LL LT'),
              costPriceTotal: <NumberFormat
              value={orderTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />,
              po_number: order?.parentOrder?.po_number
            }
          }))
          : setReports(response.result.orderData.map((order, index) => {
            const orderTotal = order.total
              ? <NumberFormat
            value={order.total}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
              : '--'
            const customerName = order.customer.primaryFirstName + ' ' + order.customer.primaryLastName
            let statusString = <div className="active-div">Delivered</div>
            if (order.orderStatus === 0) {
              statusString = <div className="disabled-div">New</div>
            }
            if (order.orderStatus === 1) {
              statusString = <div className="approved-div">In Progress</div>
            }
            if (order.orderStatus === 2) {
              statusString = <div className="approved-div">In Transit</div>
            }
            if (order.orderStatus === 3) {
              statusString = <div className="active-div">Delivered</div>
            }
            if (order.orderStatus === 4) {
              statusString = <div className="rejected-div">Rejected</div>
            }
            if (order.orderStatus === 5) {
              statusString = <div className="rejected-div">Cancelled</div>
            }
            return {
              orderNumber: order.orderNumber,
              customerName,
              orderStatus: statusString,
              costPriceInvoiceTotal: order.costPriceInvoiceTotal,
              createdAt: moment(order?.createdAt).format('LL LT'),
              total: orderTotal,
              po_number: order?.po_number
            }
          }))
        setLoader(false)
        setPaging({
          currentPage: p,
          recordPerPage: pageSize,
          totalRecord: response.result.total
        })
      } else {
        setLoader(false)
        setReports([])
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 })
        toastr.error(response.message, 'Error')
      }
    })
  }

  const handleDatePreset = (e) => {
    const findPreset = datePresets.find((item) => item.value === parseInt(e.target.value))
    setFromDate(findPreset.startDate)
    setToDate(findPreset.endDate)
  }

  const handleRow = (data) => {
    setSelectedData(data)
    setIsDisableOpen(true)
  }

  return (
        <>
        <Loader show={loader} />
        <div className="page-content">
        <Container fluid={true} className="customer-container">
          <div className="products-nav">
            <span className="products-heading">Open Order Reports</span>
            <div className='d-flex'>
            <button className="products-nav-btn products-export-btn"
            disabled={!fromDate && !toDate}
                onClick={handleExport}
                >
                  Download Excel
                </button>
              {/* {(userObj.roleId === 3 || userObj.roleId === 4)
                ? <BulkUploadModal modalType='Bulk Products Upload' productsBulkUpload={productsBulkUpload} bulkError={bulkError} bulkErrorSheet={bulkErrorSheet} sheetUrl={sheetUrl}/>
                : <BulkUploadModal modalType='Import Products Detail' importSellingPrice={importSellingPrice} bulkError={bulkError} bulkErrorSheet={bulkErrorSheet} sheetUrl={sheetUrlAdmin}/>} */}
                 <button className="report-download" disabled={!fromDate && !toDate} onClick={exportReport}>
                  <i className='bx bx-download'></i>&nbsp;Download PDF
                </button>
            </div>
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                <Row className='mb-3'>
                    <Col lg={5}>
                    <p className='mb-0'>Date presets</p>
                      <select className='date-selection' onChange={handleDatePreset}>
                        {
                          datePresets.map((preset, key) => {
                            return <option key={key} value={preset.value}>{preset.label}</option>
                          })
                        }
                      </select>
                    </Col>
                    <Col lg={1} className='d-flex justify-content-center align-items-center'>
                      <p className='text-center mb-0'>OR</p>
                    </Col>
                    <Col lg={6}>
                        <Row>
                            <Col lg={6}>
                            <p className='mb-0'>From</p>
                      <DatePicker
                          selected={fromDate}
                          onChange={(date) =>
                            setFromDate(date)
                          }
                          className="form-control pointer"
                          placeholderText="Select date"
                          showYearDropdown
                          showMonthDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                        />
                            </Col>
                            <Col lg={6}>
                            <p className='mb-0'>To</p>
                      <DatePicker
                          selected={toDate}
                          onChange={(date) =>
                            setToDate(date)
                          }
                          className="form-control pointer"
                          placeholderText="Select date"
                          maxDate={new Date()}
                          showYearDropdown
                          showMonthDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                        />
                            </Col>
                        </Row>
                    </Col>
                  </Row>
                  {
                    reports
                      ? <TableComponent
                    columns={columns}
                    content={reports}
                    paging={paging}
                    paginationHandler={paginationHandler}
                    sorting={handleSort}
                    onClick={handleRow}
                    arrowState={sortingState}
                  />
                      : <p className='mb-0 text-center pt-2' style={{ fontSize: '16px', fontWeight: '600' }}>Please select from date and to date</p>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
                  className="modal-main-body"
                  isOpen={isDisableOpen}
                  toggle={() => setIsDisableOpen(false)}
                  centered
                >
                  <div className="modal-header">
                    <button
                      type="button"
                      onClick={() => {
                        setIsDisableOpen(false)
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="modal-detail-div">
                      <p className="modal-details">Order Details</p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Order Number: </span>{' '}
                        {selectedData?.orderNumber ? selectedData?.orderNumber : '--'}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Customer Name: </span>{' '}
                        {selectedData?.customerName ? selectedData?.customerName : '--'}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Invoice Total: </span>{' '}
                        {selectedData?.costPriceInvoiceTotal ? selectedData?.costPriceInvoiceTotal : '--'}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Total: </span>{' '}
                        {(userObj.roleId === 3 || userObj.roleId === 4) ? selectedData?.costPriceTotal ? selectedData?.costPriceTotal : '--' : selectedData?.total ? selectedData?.total : '--'}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Created At: </span>{' '}
                        {selectedData?.createdAt ? moment(selectedData?.createdAt).format('LL') : '--'}
                      </p>
                      <p className="modal-warehouse-name">
                        <span className="warehouse-heading"> Order Status: </span>{' '}
                        {
                          selectedData?.orderStatus ? selectedData?.orderStatus : '--'
                        }
                      </p>
                    </div>
                  </div>
                </Modal>
      </div>
        </>
  )
}

export default ReportOrderOpen
