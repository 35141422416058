import React, { useEffect, useState } from 'react'
import { GetData } from 'apiHandler'
import { useParams } from 'react-router-dom'
import TableComponent from 'components/Table'
import moment from 'moment'
function OrderList () {
  const params = useParams()
  useEffect(() => {
    getOrderList()
  }, [])
  const [orderData, setOrderData] = useState([])
  const columns = [
    { key: 'po_number', label: 'P.O. Number' },
    { key: 'orderNumber', label: 'Order', sorting: true },
    { key: 'orderDate', label: 'Order Date', sorting: true },
    { key: 'customerName', label: 'Customer Name', sorting: true },
    { key: 'orderStatus', label: 'Order Status', sorting: true },
    { key: 'items', label: 'items', sorting: false }
  ]
  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })
  const paginationHandler = (page) => {
    getOrderList(page)
  }
  const getItemList = (products, i) => {
    return <div className='info-icon'><div className='d-flex align-items-center'><span>{`${products.length} Item (+${
      products.reduce((acc, item) => {
        acc += item.orderedQuantity
        return acc
      }, 0)
    })`}</span>{' '}<i className='bx bxs-down-arrow'></i>
    </div>
    <div className="tooltiptext1 arrow">
                                            <div className='tooltipdata'>
                                                <span className='part--id'>Part No.</span>
                                                <span className='part--id'>QTY</span>
                                            </div>
                                            <div className='tooltip-innerdata'>
                                            {
          products.map((product, key) => {
            return (
              <div className='partid-qty-div' key={key}>
                <span>{product.vendorOrderProductData.partNumber ? product.vendorOrderProductData.partNumber : '--'}</span>
                <span>{product.orderedQuantity ? product.orderedQuantity : '--'}</span>
              </div>
            )
          })
        }
                                            </div>
                                           </div></div>
  }
  const getOrderList = (p = 1) => {
    const pageSize = 20
    let url = `admin/getVendorOrderList/${params.id}?page=` +
    p +
      '&pageSize=' +
      pageSize
    if (sortingState.sortColumn != null) {
      url +=
          '&sortBy=' + sortingState.sortColumn + '&order=' + sortingState.order
    }
    GetData(url).then((response) => {
      if (response.status === true || response.status === 200) {
        if (response.result) {
          const tableData = []
          response.result.orders?.map((item, i) => {
            let statusString = <div className="pending-div">Pending</div>
            if (item.orderStatus === 1) {
              statusString = <div className="active-div">Active</div>
            }
            if (item.orderStatus === 2) {
              statusString = <div className="approved-div">Approved</div>
            }
            if (item.orderStatus === 3) {
              statusString = <div className="rejected-div">Rejected</div>
            }
            if (item.orderStatus === 4) {
              statusString = <div className="disabled-div">Disabled</div>
            }
            // const customer = item.parentOrder.customer.primaryFirstName + item.parentOrder.customer.primaryLastName
            const customer = <div>
                <div>{item.parentOrder.customer.primaryFirstName + ' ' + item.parentOrder.customer.primaryLastName}</div>
                <div>{item.parentOrder.shippingCountry + ' , ' + item.parentOrder.shippingState}</div>
            </div>
            tableData.push({
              orderNumber: item.parentOrder.orderNumber,
              orderDate: moment(item.parentOrder.orderDate).format('LL LT'),
              customerName: customer,
              orderStatus: statusString,
              items: getItemList(item.vendorOrderProducts, i),
              po_number: item?.parentOrder?.po_number,

            })
            setPaging({
              currentPage: p,
              recordPerPage: pageSize,
              totalRecord: response.result.count
            })
            setOrderData(tableData)
            return null
          })
        }
      }
    //   setPaging({
    //     currentPage: p,
    //     recordPerPage: pageSize,
    //     totalRecord: response.result.total
    //   })
    })
  }
  const handleSort = (e, column) => {
    e.preventDefault()
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'ASC' })
    } else if (sortingState.sortColumn === column) {
      if (sortingState.order === 'DESC') {
        setSortingState({ sortColumn: column, order: 'ASC' })
      } else setSortingState({ sortColumn: column, order: 'DESC' })
    } else {
      setSortingState({ sortColumn: column, order: 'ASC' })
    }
  }
  useEffect(() => {
    sortingState.sortColumn && sortingState.order && getOrderList(1)
  }, [sortingState])
  return (
        <div className="staff-main-table">
            <TableComponent
            columns={columns}
            content={orderData}
            paging={paging}
            paginationHandler={paginationHandler}
            sorting={handleSort}
            arrowState={sortingState}
            />
        </div>
  )
}
export default OrderList
