/* eslint-disable no-unused-vars */
import { GetData, PostData } from 'apiHandler'
import React, { forwardRef, useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import Modal from 'react-bootstrap/Modal'
import NumberFormat from 'react-number-format'
import Loader from '../../components/Loader'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import TableComponent from 'components/Table'
import Breadcrumbs from 'components/Common/Breadcrumb'
import { useHistory, useParams, Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import accountStatement from '../../assets/images/Group-21.svg'
import calendarIcon from '../../assets/images/calendar (1).svg'
import closeIcon from '../../assets/images/cross2.svg'
import './invoices.scss'
import moment from 'moment'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

const months = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12
}

function InvoicesDetail() {
  const date = new Date()
  const history = useHistory()
  const { id } = useParams()
  const userObj = JSON.parse(localStorage.authUser)
  const [loader, setLoader] = useState(false)
  const [invoicingCount, setInvoicingCount] = useState(null)
  const [invoiceData, setInvoiceData] = useState([])
  const [allWDs, setAllWds] = useState([])
  const [selectedWD, setSelectedWD] = useState(null)
  const [allYear, setAllYear] = useState([])
  const [selectedYear, setSelectedYear] = useState(date.getFullYear())
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })

  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })
  const [accountStatementModal, setAccountStatementModal] = useState(false)
  const [accountStatementData, setAccountStatementData] = useState([])
  const [billingCycle, setBillingCycle] = useState()
  const [year, setYear] = useState(new Date())
  const [allLocations ,setAllLocations] = useState([])
  const [locationId, setLocationId] = useState(null)

  useEffect(() => {
    if (userObj.roleId === 3 || userObj.roleId === 4) {
      getOrdersCount()
    } else {
      getAllWds()
    }
    for (let i = date.getFullYear(); i >= date.getFullYear() - 5; i--) {
      setAllYear(prevState => [...prevState, i])
    }
  }, [])

  useEffect(() => {
    if ((userObj.roleId === 3 || userObj.roleId === 4) && selectedYear) {
      getInvoiceData()
    } else {
      if (selectedWD && selectedYear) {
        getInvoiceData()
      }
    }
  }, [selectedWD, selectedYear])

  const [columns, setColumns] = useState([
    { key: 'month', label: 'Month', sorting: true },
    { key: 'invoiceCount', label: 'Total Invoices', sorting: true },
    { key: 'invoicePaidTotal', label: 'Paid Amount', sorting: true },
    { key: 'invoiceUnpaidTotal', label: 'Unpaid Amount', sorting: true },
    // { key: 'invoicePendingTotal', label: 'Pending Amount', sorting: true },
    { key: 'invoiceTotal', label: 'Total Amount', sorting: true }
  ])

  const getOrdersCount = () => {
    GetData('vendor/getInvoicingAllCount').then((response) => {
      if (response.status === true) {
        setInvoicingCount(response.result)
      }
    })
  }

  const getInvoiceData = () => {
    setLoader(true)
    const url = `admin/openAccountCustomerInvoiceReport/${id}/${selectedYear}`
    GetData(url).then((response) => {
      if (response.status === true || response.status === 200) {
        setLoader(false)
        setInvoiceData(response.result.map((invoice) => {
          return {
            month: <div className='invoice-text'>{invoice.month}</div>,
            monthName: invoice.month,
            invoiceCount: invoice.invoiceCount || '0',
            invoiceTotal: <NumberFormat
              value={invoice.invoiceTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
            /> || '0',
            invoicePaidTotal: <NumberFormat
              value={invoice.invoicePaidTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
            /> || '0',
            invoiceUnpaidTotal: <NumberFormat
              value={invoice.invoiceUnpaidTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
            /> || '0',
            invoicePendingTotal: <NumberFormat
              value={invoice.invoicePendingTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
            /> || '0'
          }
        }))
      } else {
        setLoader(false)
        setInvoiceData([])
        toastr.error(response.message, 'Error')
      }
    })
  }

  const handleRow = (data) => {
    history.push({ pathname: `/invoices/${id}/${selectedYear.toString()}/${months[data.monthName]}`, state: { vendorId: selectedWD, year: selectedYear } })
  }

  const handleSort = (e, column) => {
    e.preventDefault()
    const invoiceAllData = [...invoiceData]
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'asc' })
      invoiceAllData.data = invoiceAllData.sort((a, b) => (a[column] > b[column] ? 1 : -1))
      setInvoiceData([...invoiceAllData])
    } else if (sortingState.sortColumn === column) {
      if (sortingState.order === 'desc') {
        setSortingState({ sortColumn: column, order: 'asc' })
        invoiceAllData.data = invoiceAllData.sort((a, b) => (a[column] > b[column] ? 1 : -1))
        setInvoiceData([...invoiceAllData])
      } else {
        setSortingState({ sortColumn: column, order: 'desc' })
        invoiceAllData.data = invoiceAllData.sort((a, b) => (a[column] < b[column] ? 1 : -1))
        setInvoiceData([...invoiceAllData])
      }
    } else {
      setSortingState({ sortColumn: column, order: 'asc' })
      invoiceAllData.data = invoiceAllData.sort((a, b) => (a[column] > b[column] ? 1 : -1))
      setInvoiceData([...invoiceAllData])
    }
  }

  const getAllWds = () => {
    GetData('admin/getAllWDsWithoutPagination').then((response) => {
      if (response.status === true) {
        setSelectedWD(response?.result[0]?.id)
        setAllWds(response.result.map((wd) => {
          return { label: wd.vendorDetails?.companyName, value: wd.id }
        }))
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const handleYear = (e) => {
    setSelectedYear(parseInt(e.target.value))
  }

  function handleExport(e) {
    e.preventDefault()
    setLoader(true)
    let url = 'admin/export/invoiceData'
    if (userObj.roleId === 3 || userObj.roleId === 4) {
      url = `vendor/export/invoiceData?year=${selectedYear}`
    } else {
      url += '?vendorId=' + selectedWD + '&year=' + selectedYear
    }
    GetData(url).then((response) => {
      if (response.status === true) {
        setLoader(false)
        history.push({ pathname: '/invoicing-pdf', state: { data: response.result } })
        // const a = document.createElement('a')
        // a.href = 'https://' + response.result
        // a.download = 'download'
        // a.click()
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  // -------------------------------------Account statement Starts---------------------------------

  const handleCloseAccountStatement = () => {
    setAccountStatementModal(false)
    setYear(new Date())
  }


  // -------------------------------------Get location-id------------------------------
  useEffect(() => {

    const getCustomerLocations = () => {
      GetData('admin/getCustomerLocations/' + id).then((response) => {
        if (response.status === true) {
          const location = response.result
          location.map((primaryLocation) => {
            if (primaryLocation?.isPrimaryLocation === 1) {
              setLocationId(primaryLocation?.id)
            }
          })

          setAllLocations(location)

        } else {
          toastr.error(response.message, 'Error')
        }
      })
    }

    getCustomerLocations()
  }, [])

  const handleLocationChange = (e) => {
    setLocationId(e.target.value)
  }

  const getAccountStatement = () => {
    let url = `admin/accountStatements?customerLocationId=${locationId}&customerId=${id}&year=${moment(year).format('YYYY')}`
    setLoader(true)
    GetData(url).then((response) => {
      setLoader(false)
      if (response.status === true) {
        setAccountStatementData(response?.result?.statementsData)
        setBillingCycle(response?.result?.customerDuration)

      } else {
        setAccountStatementData(response.result?.statementsData)
      }
    })
  }

  const handleAccountStatement = () => {
    setAccountStatementModal(true)
    getAccountStatement()

  }

  //---------------------------------Get account statement modal data when year changes----------------------- 
  useEffect(() => {
    getAccountStatement()

  }, [year, locationId])

  const handleYearChange = (selectedDate) => {
    setYear(selectedDate)
  };


  const CustomInput = forwardRef((props, ref) => (
    <div className="datepicker-input-container">
      <input
        type="text"
        className="datepicker-input"
        ref={ref} 
        {...props}
        placeholder="Select year"
        readOnly
      />
      <img src={calendarIcon} alt='calendar' className="datepicker-icon" ref={ref} 
        {...props} />
    </div>
  ));

  // ------------------Dynamic color for status--------------
  const conditionalStatus = (status) => {
    let statusDiv
    if (status === 'Paid') {
      statusDiv = <div className='paid-status'>Paid</div>
    }
    if (status === 'Pending') {
      statusDiv = <div className='pending-status'>Pending</div>
    }
    if (status === 'Partially Paid') {
      statusDiv = <div className='partial-pending-status'>Partially Paid</div>
    }
    if (status === 'Due') {
      statusDiv = <div className='due-status'>Due</div>
    }
    return statusDiv
  }

  // ----------------------Statement view and download-------------------

  const handleDownloadState = (startDate, endDate) => {
    if (startDate && endDate) {
      setLoader(true)
      const url = `admin/customer/invoices/accountStatement?customerLocationId=${locationId}&customerId=${id}&startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`
      GetData(url).then((response) => {
        setLoader(false)
        if (response.status === true) {
          const a = document.createElement('a')
          a.href = 'https://' + response.result.path
          a.download = 'download'
          a.target = '_blank'
          a.click()
        } else {
          toastr.error(response.message, 'Error')
          setLoader(false)
        }
      })
    } else {
      toastr.error('Please select statement period', 'Error')
    }
  }
  return (
    <>
      <Loader show={loader} />
      <div className="page-content">
        {/* ----------------------------------------Modal view account statements--------------------- */}
        <Modal
          show={accountStatementModal}
          onHide={handleCloseAccountStatement}
          backdrop="static"
          centered
          size="xl"
          scrollable={true}
        >
          <Modal.Body >
            <div className='wrapper-account-statement'>
              <div className='close-btn-account-statement'>
                <button onClick={handleCloseAccountStatement}><img src={closeIcon} alt='close icon' /></button>
              </div>
              <div className='header-account-statement'>
                <div className='inner-header-account-statement'>
                  <div>
                    <p className='acc-statement'>Account Statements</p>
                    <p className='bill-cycle'>Billing Cycle: <span>{billingCycle} days</span></p>
                  </div>
                  <div className='inner-header-right-container'>
                    <FormControl className='pe-3 location' sx={{width: '210px'}} size='small'>
                      <InputLabel id="demo-simple-select-label">Location</InputLabel>
                      <Select
                        sx={{color: '##3D3839', fontWeight: '500', fontSize: '15px', letterSpacing: '1px'}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={locationId}
                        label="Location"
                        onChange={handleLocationChange}
                      >
                        {
                          allLocations?.map((location ) => (
                            <MenuItem sx={{color: '##3D3839', fontWeight: '500', fontSize: '15px', letterSpacing: '1px'}} value={location.id}>{location.locationName}</MenuItem>
                          ))
                        }
                        
                      </Select>
                    </FormControl>

                    <div>
                    <DatePicker
                      dateFormat="yyyy"
                      showYearPicker
                      selected={year}
                      onChange={handleYearChange}
                      customInput={<CustomInput />}
                      maxDate={new Date()}
                    />
                  </div>
                  </div>
                </div>
              </div>
              <div className='table-account-statement'>
                <div className='header-table-account-statement'>
                  <div>Statement Period</div>
                  <div>Statement</div>
                  <div>Status</div>
                  <div>Total</div>
                </div>
                {
                  accountStatementData?.length === 0 ? (<div className='row-table-account-statement'>Data Not Found...</div>) :
                    (
                      accountStatementData?.map((data, index) => {
                        const isLastElement = index === accountStatementData?.length - 1
                        return (
                          <div className='row-table-account-statement' key={data?.invoiceIds}>
                            <div>{moment(data?.cycle_start).format('MMM DD')} -   {moment(data?.cycle_end).format('MMM DD')} {isLastElement && `(Current)`}</div>
                            <div className='statement-column'><button onClick={() => handleDownloadState(data?.cycle_start, data?.cycle_end)} >View</button> </div>
                            <div>{conditionalStatus(data?.paymentStatus)}</div>
                            <div>${data?.totalAmount}</div>
                          </div>
                        )
                      })
                    )
                }
              </div>
            </div>

          </Modal.Body>
        </Modal>


        <Container fluid={true} className="customer-container">
          <div className="products-nav">
            <Breadcrumbs
              title="UI Elements"
              breadcrumbItem={'Customer Invoices'}
              icon={
                <i
                  className="bx bx-arrow-back me-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push('/invoices')}
                ></i>
              }
            />
            {/* <div><button className='invoicing-download-btn' onClick={handleExport}>Download</button></div> */}
          </div>
          {(userObj.roleId === 3 || userObj.roleId === 4) && <Row className=' mt-3'>
            <Col lg={3}>
              <Card>
                <CardBody className='d-flex align-items-center justify-content-between'>
                  <div>
                    <p>Total Revenue</p>
                    <p className='mb-0 h3'>
                      <NumberFormat
                        value={
                          invoicingCount?.totalRevenue}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                      /></p>
                  </div>
                  <div className='invoicing-icon-container'>
                    <i className='mdi mdi-file-chart invoicing-icon'></i>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card>
                <CardBody className='d-flex align-items-center justify-content-between'>
                  <div>
                    <p>Total Orders</p>
                    <p className='mb-0 h3'>{invoicingCount?.totalOrders}</p>
                  </div>
                  <div className='invoicing-icon-container'>
                    <i className='mdi mdi-file-chart invoicing-icon'></i>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card>
                <CardBody className='d-flex align-items-center justify-content-between'>
                  <div>
                    <p>Delivered Orders</p>
                    <p className='mb-0 h3'>{invoicingCount?.deliveredOrders}</p>
                  </div>
                  <div className='invoicing-icon-container'>
                    <i className='mdi mdi-cart-check invoicing-icon'></i>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card>
                <CardBody className='d-flex align-items-center justify-content-between'>
                  <div>
                    <p>Rejected Orders</p>
                    <p className='mb-0 h3'>{invoicingCount?.rejectedOrders}</p>
                  </div>
                  <div className='invoicing-icon-container'>
                    <i className='mdi mdi-file-excel invoicing-icon'></i>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>}
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className={`warehouse-navbar mb-3 ${(userObj.roleId === 3 || userObj.roleId === 4) && 'justify-content-end'}`}>
                    <div>
                      <select className='year-dropdown' onChange={handleYear}>
                        {allYear.map((year) => {
                          return <option key={year} >{year}</option>
                        })}
                      </select>
                    </div>

                    <div className='account-statements-wrapper'>
                      <span onClick={handleAccountStatement}>
                        <img src={accountStatement} alt="view statements" />
                        View Account Statements
                      </span>
                    </div>
                  </div>
                  <TableComponent
                    columns={columns}
                    content={invoiceData}
                    paging={paging}
                    onClick={handleRow}
                    sorting={handleSort}
                    arrowState={sortingState}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
export default InvoicesDetail
