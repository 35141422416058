/* eslint-disable no-unused-vars */
import { GetData, PostData } from 'apiHandler'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import NumberFormat from 'react-number-format'
import Loader from '../../components/Loader'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import TableComponent from 'components/Table'
import { useHistory } from 'react-router-dom'

function InvoicingTable () {
  const date = new Date()
  const history = useHistory()
  const userObj = JSON.parse(localStorage.authUser)
  const [loader, setLoader] = useState(false)
  const [invoicingCount, setInvoicingCount] = useState(null)
  const [invoiceData, setInvoiceData] = useState([])
  const [allWDs, setAllWds] = useState([])
  const [selectedWD, setSelectedWD] = useState(null)
  const [allYear, setAllYear] = useState([])
  const [selectedYear, setSelectedYear] = useState(date.getFullYear())
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0
  })

  const [sortingState, setSortingState] = useState({
    sortColumn: null,
    order: null
  })

  useEffect(() => {
    if (userObj.roleId === 3 || userObj.roleId === 4) {
      getOrdersCount()
    } else {
      getAllWds()
    }
    for (let i = date.getFullYear(); i >= date.getFullYear() - 5; i--) {
      setAllYear(prevState => [...prevState, i])
    }
  }, [])

  useEffect(() => {
    if ((userObj.roleId === 3 || userObj.roleId === 4) && selectedYear) {
      getInvoiceData()
    } else {
      if (selectedWD && selectedYear) {
        getInvoiceData()
      }
    }
  }, [selectedWD, selectedYear])

  const [columns, setColumns] = useState([
    { key: 'monthName', label: 'Month', sorting: true },
    { key: 'orderCount', label: 'Total Orders', sorting: true },
    { key: 'totalOrderAmount', label: 'Order Value', sorting: true },
    { key: 'deliveredCount', label: 'Delivered Orders', sorting: true },
    { key: 'invoiceTotal', label: 'Invoiced Value', sorting: true }
  ])

  const getOrdersCount = () => {
    GetData('vendor/getInvoicingAllCount').then((response) => {
      if (response.status === true) {
        setInvoicingCount(response.result)
      }
    })
  }

  const getInvoiceData = () => {
    setLoader(true)
    const userObj = JSON.parse(localStorage.authUser)
    const body = {
      vendorId: selectedWD,
      year: selectedYear
    }
    let url = 'admin/invoiceData'
    if (userObj.roleId === 3 || userObj.roleId === 4) {
      url = 'vendor/invoiceData'
    //   setColumns([
    //     { key: 'monthName', label: 'Month' },
    //     { key: 'orderCount', label: 'Total Orders' },
    //     { key: 'totalOrderAmount', label: 'Order Value' },
    //     { key: 'deliveredCount', label: 'Delivered Orders' },
    //     { key: 'invoiceTotal', label: 'Invoiced Value' }
    //   ])
    }
    PostData(url, body).then((response) => {
      if (response.status === true || response.status === 200) {
        setLoader(false)
        if (userObj.roleId === 3 || userObj.roleId === 4) {
          setInvoiceData(response.result.map((invoice) => {
            return {
              monthName: <div className='invoice-text'>{invoice.monthname}</div>,
              orderCount: parseInt(invoice.orderCount) || '0',
              totalOrderAmount: invoice.totalOrderAmount || '0',
              deliveredCount: parseInt(invoice.deliveredCount) || '0',
              invoiceTotal: invoice.invoiceTotal || '0',
              month: invoice.month
            }
          }))
        } else {
          setInvoiceData(response.result.map((invoice) => {
            return {
              monthName: <div className='invoice-text'>{invoice.monthname}</div>,
              orderCount: parseInt(invoice.orderCount) || '0',
              totalOrderAmount: invoice?.totalOrderAmount || '0',
          //   totalOrderAmount: invoice?.totalOrderAmount ? <NumberFormat
          //   value={`${(invoice?.totalOrderAmount !== '' && parseFloat(invoice?.totalOrderAmount)) + (invoice?.shippingTotal !== '' && parseFloat(invoice?.shippingTotal))}`}
          //   displayType={'text'}
          //   thousandSeparator={true}
          //   decimalScale={2}
          //   prefix={'$'}
          // /> : '--',
              deliveredCount: parseInt(invoice.deliveredCount) || '0',
              invoiceTotal: invoice.invoiceTotal || '0',
              month: parseInt(invoice.month)
            }
          }))
        }
      } else {
        setLoader(false)
        setInvoiceData([])
        toastr.error(response.message, 'Error')
      }
    })
  }

  const handleRow = (data) => {
    history.push({ pathname: '/invoicing/' + data.month, state: { vendorId: selectedWD, year: selectedYear } })
  }

  const handleSort = (e, column) => {
    e.preventDefault()
    const invoiceAllData = [...invoiceData]
    if (sortingState.sortColumn == null) {
      setSortingState({ sortColumn: column, order: 'asc' })
      invoiceAllData.data = invoiceAllData.sort((a, b) => (a[column] > b[column] ? 1 : -1))
      setInvoiceData([...invoiceAllData])
    } else if (sortingState.sortColumn === column) {
      if (sortingState.order === 'desc') {
        setSortingState({ sortColumn: column, order: 'asc' })
        invoiceAllData.data = invoiceAllData.sort((a, b) => (a[column] > b[column] ? 1 : -1))
        setInvoiceData([...invoiceAllData])
      } else {
        setSortingState({ sortColumn: column, order: 'desc' })
        invoiceAllData.data = invoiceAllData.sort((a, b) => (a[column] < b[column] ? 1 : -1))
        setInvoiceData([...invoiceAllData])
      }
    } else {
      setSortingState({ sortColumn: column, order: 'asc' })
      invoiceAllData.data = invoiceAllData.sort((a, b) => (a[column] > b[column] ? 1 : -1))
      setInvoiceData([...invoiceAllData])
    }
  }

  const getAllWds = () => {
    setLoader(true)
    GetData('admin/getAllWDsWithoutPagination').then((response) => {
      setLoader(false)
      if (response.status === true) {
        setSelectedWD(response?.result[0]?.id)
        setAllWds(response.result.map((wd) => {
          return { label: wd.vendorDetails?.companyName, value: wd.id }
        }))
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  const handleSelect = (e) => {
    setSelectedWD(e.target.value)
  }

  const handleYear = (e) => {
    setSelectedYear(parseInt(e.target.value))
  }

  function handleExport (e) {
    e.preventDefault()
    setLoader(true)
    let url = 'admin/export/invoiceData'
    if (userObj.roleId === 3 || userObj.roleId === 4) {
      url = `vendor/export/invoiceData?year=${selectedYear}`
    } else {
      url += '?vendorId=' + selectedWD + '&year=' + selectedYear
    }
    GetData(url).then((response) => {
      if (response.status === true) {
        setLoader(false)
        history.push({ pathname: '/invoicing-pdf', state: { data: response.result } })
        // const a = document.createElement('a')
        // a.href = 'https://' + response.result
        // a.download = 'download'
        // a.click()
      } else {
        toastr.error(response.message, 'Error')
      }
    })
  }

  return (
        <>
        <Loader show={loader} />
        <div className="page-content">
            <Container fluid={true} className="customer-container">
                <div className="products-nav">
                <div className="products-heading">Invoicing</div>
                <div><button className='invoicing-download-btn' onClick={handleExport}>Download</button></div>
                </div>
              { (userObj.roleId === 3 || userObj.roleId === 4) && <Row className=' mt-3'>
                    <Col lg={3}>
                        <Card>
                        <CardBody className='d-flex align-items-center justify-content-between'>
                                <div>
                                    <p>Total Revenue</p>
                                    <p className='mb-0 h3'>
                                <NumberFormat
                                value={
                                  parseInt(invoicingCount?.totalRevenue)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              /></p>
                                </div>
                                <div className='invoicing-icon-container'>
                                    <i className='mdi mdi-file-chart invoicing-icon'></i>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={3}>
                    <Card>
                    <CardBody className='d-flex align-items-center justify-content-between'>
                                <div>
                                    <p>Total Orders</p>
                                    <p className='mb-0 h3'>{invoicingCount?.totalOrders}</p>
                                </div>
                                <div className='invoicing-icon-container'>
                                    <i className='mdi mdi-file-chart invoicing-icon'></i>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={3}>
                        <Card>
                        <CardBody className='d-flex align-items-center justify-content-between'>
                                <div>
                                    <p>Delivered Orders</p>
                                    <p className='mb-0 h3'>{invoicingCount?.deliveredOrders}</p>
                                </div>
                                <div className='invoicing-icon-container'>
                                    <i className='mdi mdi-cart-check invoicing-icon'></i>
                                </div>
                            </CardBody>
                            </Card>
                    </Col>
                    <Col lg={3}>
                    <Card>
                            <CardBody className='d-flex align-items-center justify-content-between'>
                                <div>
                                    <p>Rejected Orders</p>
                                    <p className='mb-0 h3'>{invoicingCount?.rejectedOrders}</p>
                                </div>
                                <div className='invoicing-icon-container'>
                                    <i className='mdi mdi-file-excel invoicing-icon'></i>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>}
                <Row className="mt-3">
                    <Col lg={12}>
                    <Card>
                        <CardBody>
                            <div className={`warehouse-navbar mb-3 ${(userObj.roleId === 3 || userObj.roleId === 4) && 'justify-content-end'}`}>
                               {(userObj.roleId === 1 || userObj.roleId === 2) && <div>
                                    <span className='select__wd'>Select WD:</span>
                                    <select className='select--wd--drop' onChange={handleSelect}>
                                       {allWDs.map((wd, i) => {
                                         return <option key={i} value={wd.value}>{wd.label}</option>
                                       })}
                                    </select>
                                </div>}
                                <div>
                                    <select className='year-dropdown' onChange={handleYear}>
                                         {allYear.map((year) => {
                                           return <option key={year} >{year}</option>
                                         })}
                                    </select>
                                </div>
                            </div>
                            <TableComponent
                                columns={columns}
                                content={invoiceData}
                                paging={paging}
                                onClick={handleRow}
                                sorting={handleSort}
                                arrowState={sortingState}
                                loading={loader}
                            />
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            </Container>
            </div>
            </>
  )
}
export default InvoicingTable
