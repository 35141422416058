/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import TableComponent from "../../components/Table";
import { GetData, PostData, PutData } from "../../apiHandler";
import Loader from "../../components/Loader";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ConfirmationModal from "../../components/Common/ConfirmationModal";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import { useHistory } from "react-router-dom";

let filterByStatus = 'all'
let searchTimer
let filterByKeyObject = { key: 'all' }
const MasterCustomer = () => {
  const history = useHistory();
  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0,
  });
  const [loader, setLoader] = useState(false);
  const [masterCustomers, setMasterCustomers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [singlebtn, setSinglebtn] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState({
    label: 'All',
    key: 'all'
  })
  const [selectStatus, setSelectStatus] = useState('Select Status')
  const [searchText, setSearchText] = useState('')
  const [isStatusOpen, setIsStatusOpen] = useState(false)

  const columns = [
    { key: "companyName", label: "Company Name" },
    { key: "email", label: "Email" },
    { key: "status", label: "Status" },
    { key: "action", label: "Action" },
  ];

  const statusTypesData =
  [
    { label: 'Pending', key: '0' },
    { label: 'Active', key: '1' },
    { label: 'Approved', key: '2' },
    { label: 'Rejected', key: '3' },
    { label: 'Disabled', key: '4' }
  ]

  const filterTypes = [
    { label: 'All', key: 'all' },
    { label: 'Company Name', key: 'companyName' },
    { label: 'Email', key: 'email' },
    // { label: 'Status', key: 'status' },
  ]

  useEffect(() => {
    fetchMasterCustomers();
    fetchCustomerList();
  }, []);

  const fetchMasterCustomers = (p = 1) => {
    const pageSize = 20;
    let url = "admin/master-customer-list?page=" + p + "&pageSize=" + pageSize;
    if (filterByStatus !== 'all') {
      url += '&status=' + filterByStatus
    }
    if (filterByKeyObject.string) {
      url +=
        '&filterBy=' +
        filterByKeyObject.key +
        '&search=' +
        filterByKeyObject.string
    }
    // if (sortingState.sortColumn != null) {
    //   url +=
    //     '&sortBy=' + sortingState.sortColumn + '&order=' + sortingState.order
    // }
    setLoader(true);
    GetData(url).then((response) => {
      setLoader(false);
      if (response.status === true) {
        if (response?.result?.data.length) {
          const updatedCustomers = response.result.data.map((customer) => {
            let statusString;
            switch (customer.status) {
              case 1:
                statusString = <div className="active-div">Active</div>;
                break;
              case 2:
                statusString = <div className="approved-div">Approved</div>;
                break;
              case 3:
                statusString = <div className="rejected-div">Rejected</div>;
                break;
              case 4:
                statusString = <div className="disabled-div">Disabled</div>;
                break;
              default:
                statusString = <div className="pending-div">Pending</div>;
            }
            return {
              ...customer,
              status: statusString,
              statusId: customer.status,
              action: ["delete"],
            };
          });
          setMasterCustomers(updatedCustomers);
          setPaging({
            currentPage: p,
            recordPerPage: pageSize,
            totalRecord: response?.result?.total,
          });
        } else {
          setPaging({
            currentPage: p,
            recordPerPage: pageSize,
            totalRecord: 0,
          });
          setMasterCustomers([]);
        }
      } else {
        setMasterCustomers([]);
        setPaging({ currentPage: 1, recordPerPage: pageSize, totalRecord: 0 });
        toastr.error(response?.message, "Error");
      }
    });
  };

  const fetchCustomerList = () => {
    GetData(`admin/customers/independent-list?page=1&pageSize=20`).then(
      (response) => {
        if (response.status) {
          const customerOptions = response.result.data.map((customer) => ({
            label: customer.companyName,
            value: customer.id,
          }));
          setCustomers(customerOptions);
        } else {
          toastr.error(response.message, "Error");
        }
      }
    );
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleAddMasterCustomer = () => {
    PutData("admin/mark-as-master-customer", {
      customerIds: selectedCustomers,
    }).then((response) => {
      if (response.status) {
        fetchMasterCustomers();
        fetchCustomerList()
        toggleModal();
      } else {
        toastr.error(response.message, "Error");
      }
    });
  };

  const handleDeleteMasterCustomer = (id) => {
    setCustomerToDelete(id);
    toggleDeleteModal();
  };

  const confirmDelete = () => {
    setLoader(true);
    PutData("admin/unmark-from-master-customer", {
      customerIds: [customerToDelete],
    }).then((response) => {
      setLoader(false);
      if (response.status) {
        fetchMasterCustomers();
        fetchCustomerList()
        toggleDeleteModal();
      } else {
        toastr.error(response.message, "Error");
      }
    });
  };

  const handleActions = (action, customer) => {
    if (action === "delete") {
      handleDeleteMasterCustomer(customer.id);
    }
  };

  const handleRow = (data) => {
    history.push("/master-customer/" + data?.id);
  };

  const paginationHandler = (page) => {
    fetchMasterCustomers(page);
  };

  const filterByKey = (obj) => {
    setSelectStatus('Select Status')
    filterByKeyObject.key = obj.key
    setSelectedFilter(obj)
    if (filterByKeyObject.string) {
      fetchMasterCustomers(1)
    }
  }

  const searchByText = (t) => {
    setSearchText(t)
    if (searchTimer) {
      clearTimeout(searchTimer)
    }
    searchTimer = setTimeout(() => {
      filterByKeyObject.string = t
      fetchMasterCustomers(1)
    }, 2000)
  }

  const selectByStatus = (obj) => {
    filterByKeyObject.string = obj.key
    fetchMasterCustomers(1)
    setSelectStatus(obj.label)
  }
  
  const handleRemoveSearchWord = () => {
    setSearchText('')
    filterByKeyObject.string = ''
    fetchMasterCustomers(1)
  }

  return (
    <>
      <Loader show={loader} />
      <div className="page-content">
        <MetaTags>
          <title>Master Customer</title>
        </MetaTags>
        <Container fluid={true}>
          <div className="breadcrumb-main-nav">
            <Breadcrumbs title="UI Elements" breadcrumbItem="Master Customer" />
            {customers.length && (
              <div className="imp-exp-div">
                <button className="add-new-customer-btn" onClick={toggleModal}>
                  + Add Master Customers
                </button>
              </div>
            )}
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                <div className="d-flex mb-4 justify-content-end drop-search">
                      <Dropdown
                        isOpen={singlebtn}
                        toggle={() => setSinglebtn(!singlebtn)}
                      >
                        <DropdownToggle className="customer-select text-dark">
                          Filter By{' '}
                          <span className="font-weight-bold">
                            {selectedFilter.label}
                          </span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {filterTypes.map((filterObj, key) => {
                            return (
                              <DropdownItem
                                key={key}
                                onClick={() => filterByKey(filterObj)}
                              >
                                {filterObj.label}
                              </DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                      {filterByKeyObject.key === 'status'
                        ? (
                          <Dropdown
                            isOpen={isStatusOpen}
                            toggle={() => setIsStatusOpen(!isStatusOpen)}
                          >
                          <DropdownToggle className="customer-select status-dropdown text-dark">
                           {selectStatus}
                            <i className=" ms-2 bx bx-caret-down" />
                          </DropdownToggle>
                          <DropdownMenu className='status-dropdown-menu'>
                            {statusTypesData.map((item) => {
                              return (
                                <DropdownItem
                                  key={item.key}
                                  onClick={() => selectByStatus(item)}
                                >
                                {item.label}
                                </DropdownItem>
                              )
                            })}
                          </DropdownMenu>
                        </Dropdown>)
                        : (<div className="customer-search-form">
                        <input
                          className="form-control input-border customer-input-border"
                          type="text"
                          placeholder="Search"
                          onChange={(e) => searchByText(e.target.value)}
                          value={searchText}
                        />
                        {searchText && (
                          <i
                            className="mdi mdi-close empty-search-icon"
                            onClick={() => handleRemoveSearchWord()}
                          ></i>
                        )}
                        <i className="bx bx-search-alt-2 search-icon"></i>
                      </div>)
                      }
                    </div>
                  <TableComponent
                    columns={columns}
                    content={masterCustomers}
                    paging={paging}
                    onClick={handleRow}
                    handleActions={handleActions}
                    paginationHandler={paginationHandler}
                    loading={loader}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Select Customers</ModalHeader>
        <ModalBody>
          <Select
            options={customers}
            onChange={(selected) =>
              setSelectedCustomers(selected.map((option) => option.value))
            }
            closeMenuOnSelect={false}
            isMulti
            placeholder="Select Customers"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAddMasterCustomer}>
            Add
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={toggleDeleteModal}
        onConfirm={confirmDelete}
        message="Are you sure you want to delete this master customer?"
      />
    </>
  );
};

export default MasterCustomer;
