import PropTypes from 'prop-types'
import React from 'react'

import { connect } from 'react-redux'
// import { Row, Col } from "reactstrap";
import { Link } from 'react-router-dom'

// Reactstrap
// import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown'
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu'
// import megamenuImg from "../../assets/images/megamenu-img.png";

// import images
// import github from "../../assets/images/brands/github.png";
// import bitbucket from "../../assets/images/brands/bitbucket.png";
// import dribbble from "../../assets/images/brands/dribbble.png";
// import dropbox from "../../assets/images/brands/dropbox.png";
// import mail_chimp from "../../assets/images/brands/mail_chimp.png";
// import slack from "../../assets/images/brands/slack.png";

import logo from '../../assets/images/logo.svg'
import QLogo from '../../assets/images/q-logo.svg'

// i18n

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType
} from '../../store/actions'

const Header = props => {
  const userObj = JSON.parse(localStorage.authUser)
  function tToggle () {
    const body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable')
    } else {
      body.classList.toggle('vertical-collpsed')
      body.classList.toggle('sidebar-enable')
    }
  }
  // const [currentRole, setCurrentRole] = useState(1)
  // useEffect(() => {
  //   if (localStorage.authUser) {
  //     const userObj = JSON.parse(localStorage.authUser)
  //     setCurrentRole(userObj.roleId)
  //   }
  // }, [])

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">

            <div className="navbar-brand-box d-lg-none d-md-block">
              { userObj.roleId === 1 || userObj.roleId === 2
                ? <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>
                : null
              }

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={QLogo} alt="" height="30" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

          </div>
          <div className="d-flex">
            {/* {
              (userObj?.roleId === 1 || userObj?.roleId === 2) && (
                <NotificationDropdown />
              )
            } */}
            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType
  } = state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType
})(Header)
