/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader";
import { Container, Row, Col, Card, CardBody, Modal } from "reactstrap";
import toastr from "toastr";
import TableComponent from "components/Table";
import "toastr/build/toastr.min.css";
import { GetData, PostData } from "apiHandler";
import moment from "moment";
import { Link } from "react-router-dom";
import { io } from "socket.io-client";
import { useLocation } from "react-router-dom";

const Notifications = () => {
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const userObj = JSON.parse(localStorage.authUser);
  const [notifications, setNotifications] = useState({});
  const [detailsModal, setDetailsModal] = useState(false);

  const [columns, setColumns] = useState([
    { key: "sNo", label: "S.No." },
    { key: "title", label: "Title" },
    { key: "notification_type", label: "Type" },
    { key: "generated_by", label: "From" },
    { key: "formattedDate", label: "Date" },
  ]);

  const [paging, setPaging] = useState({
    currentPage: 1,
    recordPerPage: 20,
    totalRecord: 0,
  });

  const paginationHandler = (page) => {
    getNotifications(page);
  };

  // -----------------Function for socket connection----------------
  const connectSocket = () => {
    const socket = io(process.env.REACT_APP_API_BASE_URL);

    socket.on(`globalNotification_${userObj?.userId}`, (notificationCount) => {
      if (location?.pathname === "/notifications") {
        getNotifications();
      }
    });

    return () => {
      socket.disconnect();
    };
  };

  useEffect(() => {
    getNotifications(1);
    connectSocket();
  }, []);

  // --------------------Function to update notifications-------------
  const updateNotification = (notificationId) => {
    let apiUrl = "admin/updateNofication";
    let payload = {
      action: "readStatusUpdate",
      is_read: 1,
      notification_id: notificationId,
    };

    PostData(apiUrl, payload).then((response) => {
      if (response.status === true) {
        getNotifications();
      } else {
        toastr.error(response.message, "Error");
      }
    });
  };

  const getNotifications = (p = 1) => {
    setLoader(true);
    const pageSize = 20;
    let url = `admin/getGlobalNotifications?page=${p}&pageSize=${pageSize}`;

    const formatValue = (isRead, value) => {
      return isRead ? (
        <span className="text-capitalize">{value}</span>
      ) : (
        <b className="text-capitalize">{value}</b>
      );
    };

    const formatDate = (isRead, date) => {
      const formattedDate = moment(date)?.format("LL");
      return isRead ? formattedDate : <b>{formattedDate}</b>;
    };

    GetData(url)
      .then((response) => {
        if (response.status && response.code === 200) {
          setLoader(false);
          const notificationsData = response?.result?.notifications;

          const updatedNotifications =
            notificationsData?.parsedNotifications.map(
              (notification, index) => ({
                ...notification,
                sNo: formatValue(notification?.is_read, index + 1),
                title: formatValue(notification?.is_read, notification?.title),
                notification_type: formatValue(
                  notification?.is_read,
                  notification?.notification_type.toLowerCase()
                ),
                generated_by: formatValue(
                  notification?.is_read,
                  notification?.generated_by?.replace(/_/g, " ").toLowerCase()
                ),
                formattedDate: formatDate(
                  notification?.is_read,
                  notification?.createdAt
                ),
                additional_data: {
                  ...notification?.additional_data,
                  LINK: (
                    <Link to={notification?.additional_data?.LINK}>
                      View More Details
                    </Link>
                  ),
                },
              })
            );

          setNotifications({
            parsedNotifications: updatedNotifications,
            readCount: notificationsData.readCount,
            unreadCount: notificationsData.unreadCount,
          });
          setPaging({
            currentPage: p,
            recordPerPage: pageSize,
            totalRecord: notificationsData?.total,
          });
        } else {
          setLoader(false);
          toastr.error(response.message, "Error");
        }
      })
      .catch((err) => {
        setLoader(false);
        toastr.error(err, "Error");
      });
  };

  const handleRow = (data) => {
    if (data?.is_read === false) {
      updateNotification(data?.notification_id);
    }
    setSelectedData(data);
    setDetailsModal(true);
  };

  return (
    <>
      <Loader show={loader} />
      <div className="page-content">
        <Container fluid={true} className="customer-container">
          <div className="products-nav">
            <span className="products-heading">Notifications</span>
          </div>
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="order-table">
                    <TableComponent
                      columns={columns}
                      content={notifications?.parsedNotifications || []}
                      paging={paging}
                      paginationHandler={paginationHandler}
                      // arrowState={sortingState}
                      onClick={handleRow}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          className="modal-main-body"
          isOpen={detailsModal}
          toggle={() => setDetailsModal(false)}
          centered
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={() => {
                setDetailsModal(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-detail-div">
              <p className="modal-details">Notification Details</p>
              <p className="modal-warehouse-name">
                <span className="warehouse-heading"> Message: </span>{" "}
                {selectedData?.message ? selectedData?.message : "--"}
              </p>
              <p className="modal-warehouse-name">
                <span className="warehouse-heading"> Received: </span>{" "}
                {selectedData?.createdAt
                  ? moment(selectedData?.createdAt).fromNow()
                  : "--"}
              </p>
              {selectedData?.additional_data && (
                <p className="modal-warehouse-name">
                  {Object.entries(selectedData.additional_data).map(
                    ([key, value], index) => (
                      <span key={index}>
                        <span className="warehouse-heading">
                          {key
                            .toLowerCase()
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (char) => char.toUpperCase())}
                          :{" "}
                        </span>
                        {value ? value : "--"}
                        <br />
                      </span>
                    )
                  )}
                </p>
              )}
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Notifications;
