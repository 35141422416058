import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Button, Input } from "reactstrap";
import { GetData, PostData, PutData } from "apiHandler";
import { useParams, useHistory } from "react-router-dom";
import Loader from "../../components/Loader";
import toastr from "toastr";
import moment from "moment";
import "toastr/build/toastr.min.css";

const DisputeDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [dispute, setDispute] = useState(null);
  const [newMessage, setNewMessage] = useState("");

  const statusOptions = ["active", "closed", "hold"];
  const messagesEndRef = React.useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      const container = messagesEndRef.current.parentElement;
      container.scrollTop = container.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [dispute?.messages]);

  const StatusBadge = ({ status }) => {
    const getStatusStyles = (status) => {
      switch (status.toLowerCase()) {
        case "active":
          return { background: "#e1f3dc", color: "#237804" };
        case "closed":
          return { background: "#ffcccc", color: "#d32f2f" };
        case "hold":
          return { background: "#fff3cd", color: "#856404" };
        default:
          return { background: "#e0e0e0", color: "#000" };
      }
    };

    return (
      <span
        style={{
          borderRadius: "12px",
          fontSize: "12px",
          lineHeight: "24px",
          padding: "0 18px",
          textTransform: "capitalize",
          width: "fit-content",
          display: "inline-block",
          fontWeight: "500",
          ...getStatusStyles(status),
        }}
      >
        {status}
      </span>
    );
  };

  const getDisputeDetails = async () => {
    setLoader(true);
    try {
      const response = await GetData(`admin/disputes/${id}`);
      if (response.status === true) {
        setDispute(response.result);
      } else {
        toastr.error(response?.message, "Error");
      }
    } catch (error) {
      toastr.error("Failed to fetch dispute details", "Error");
    }
    setLoader(false);
  };

  const handleStatusChange = async (newStatus) => {
    setLoader(true);
    try {
      const response = await PutData(`admin/disputes/${id}`, {
        status: newStatus,
      });

      if (response.status === true) {
        getDisputeDetails();
        toastr.success(response?.message);
      } else {
        toastr.error(response?.message, "Error");
      }
    } catch (error) {
      toastr.error("Failed to update status", "Error");
    }
    setLoader(false);
  };

  const handleAddMessage = async () => {
    if (!newMessage.trim()) return;

    setLoader(true);
    try {
      const response = await PostData(`admin/disputes/${id}/messages`, {
        message: newMessage,
      });

      if (response.status === true) {
        getDisputeDetails();
        setNewMessage("");
        toastr.success(response?.message);
      } else {
        toastr.error(response?.message, "Error");
      }
    } catch (error) {
      toastr.error("Failed to send message", "Error");
    }
    setLoader(false);
  };

  const renderIssueDetails = () => {
    if (dispute.issueType.toLowerCase() === "others") {
      return (
        <>
          <p>
            <strong>Issue Type:</strong> {dispute.issueType.toUpperCase()}
          </p>
          <p>
            <strong>Additional Comments:</strong> {dispute.additionalComment}
          </p>
        </>
      );
    } else if (dispute.issueType.toLowerCase() === "order") {
      return (
        <>
          <p>
            <strong>Issue Type:</strong> {dispute.issueType.toUpperCase()}
          </p>
          <p>
            <strong>Order ID:</strong> {dispute.vendorOrderId}
          </p>
        </>
      );
    } else {
      return (
        <>
          <p>
            <strong>Issue Type:</strong> {dispute.issueType.toUpperCase()}
          </p>
        </>
      );
    }
  };

  useEffect(() => {
    getDisputeDetails();
  }, [id]);

  return (
    <>
      <Loader show={loader} />
      <div className="page-content">
        <Container fluid={true}>
          {dispute && (
            <>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div>
                  <div className="d-flex">
                    <i
                      className="bx bx-arrow-back me-2"
                      style={{ fontSize: "24px", cursor: "pointer" }}
                      onClick={() => history.push("/disputes")}
                    />
                    <div>
                      <h4 className="mb-1 d-flex align-items-center gap-2">
                        <span>#{dispute.complainNumber}</span>
                        <StatusBadge status={dispute.status} />
                      </h4>
                      <p className="text-muted mb-0">
                        Raised by {dispute.name}
                      </p>
                    </div>
                  </div>
                </div>
                {
                  dispute.status !== "closed" && (
                    <div className="d-flex align-items-center">
                    <Input
                      type="select"
                      value={dispute.status}
                      onChange={(e) => handleStatusChange(e.target.value)}
                      className="me-2"
                      style={{ width: "150px" }}
                    >
                      {statusOptions.map((option) => (
                        <option key={option} value={option}>
                          {option.charAt(0).toUpperCase() + option.slice(1)}
                        </option>
                      ))}
                    </Input>
                    {/* <StatusBadge status={dispute.status} /> */}
                  </div>
                  )
                }
              </div>
              <Card className="mb-4">
                <CardBody>
                  <Row className="mb-4">
                    <Col md={6}>
                      <p>
                        <strong>Company:</strong> {dispute.companyName}
                      </p>
                      <p>
                        <strong>Email:</strong> {dispute.email}
                      </p>
                      <p>
                        <strong>Phone:</strong> {dispute.phoneNumber}
                      </p>
                    </Col>
                    <Col md={6}>
                      {renderIssueDetails()}
                      <p>
                        <strong>Created At:</strong>{" "}
                        {moment(dispute.createdAt).format("LL")}
                      </p>
                    </Col>
                  </Row>

                  {dispute.documents && dispute.documents.length > 0 && (
                    <div className="mb-4">
                      <h5>Attached Documents</h5>
                      <div className="d-flex gap-2">
                        {dispute.documents.map((doc, index) => (
                          <a
                            key={index}
                            href={doc}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-light btn-sm"
                          >
                            Document {index + 1}
                          </a>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="messages-thread">
                    <h5 className="mb-3">Messages</h5>
                    <div
                      style={{
                        maxHeight: "250px",
                        overflowY: "auto",
                      }}
                    >
                      {dispute.messages.map((message, index) => (
                        <div
                          key={index}
                          className="message-item p-3 mb-3"
                          style={{
                            background: "#f8f9fa",
                            borderRadius: "8px",
                            borderLeft: `4px solid ${
                              message.createdByRole === "admin"
                                ? "#d54242"
                                : "#0069a7"
                            }`,
                            marginLeft: message.createdByRole === "admin" ? "16px" : "0",
                          }}
                        >
                          <div className="d-flex justify-content-between mb-2">
                            <strong>{message.createdBy}</strong>
                            <small className="text-muted">
                              {moment(message.createdAt).format("LL, LT")}
                            </small>
                          </div>
                          <div>{message.message}</div>
                        </div>
                      ))}
                      <div ref={messagesEndRef} />
                    </div>
                  </div>
                  {dispute.status !== "closed" && (
                    <div className="add-message mt-4">
                      <Input
                        type="textarea"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Type your message here..."
                        rows="3"
                      />
                      <Button
                        color="primary"
                        className="mt-3"
                        onClick={handleAddMessage}
                      >
                        Send Message
                      </Button>
                    </div>
                  )}
                </CardBody>
              </Card>
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default DisputeDetail;
